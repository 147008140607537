import React, { useEffect, useState } from "react";
import {
  Button,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  Chip,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { OrderOnDemandApi } from "../../../API/ZyppIt";
import { AllocationServiceabilityApi } from "../../../API/AllocationServiceability";
export default function MerchantAllocationServiceabilityTable() {
  const [loading, setLoading] = useState(true);
  const [merchantData, setMerchantData] = useState<any>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<any>(81);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [searchVal, setSearchVal] = useState<string | null>(null);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");
  const [merchantCodList, setMerchantCodList] = useState<any>([]);
  const [openLimitDialog, setOpenLimitDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState<any>(null);
  const [rowStates, setRowStates] = useState<any>({});
  const cityCodeMap: { [key: string]: string } = {
    BLR: "Bangalore",
    DL: "Delhi",
    MUM: "Mumbai",
    GGN: "Gurgaon",
    FDB: "Faridabad",
    GZB: "Ghaziabad",
    HYD: "Hyderabad",
    Noida: "Noida",
    GNIDA: "Greater Noida",
  };
  const filteredMerchantCodList = merchantCodList.filter((row: any) => {
    const cityName = cityCodeMap[row.city] || row.city;
    return cityName.toLowerCase().includes(searchVal?.toLowerCase() || "");
  });

  useEffect(() => {
    const api = new OrderOnDemandApi();
    api
      .merchantList()
      .then((e) => {
        setMerchantData(e.data.data);
      })
      .catch((error) => {
        console.error("API call error: ", error);
      });
  }, []);
  const handleTableData = () => {
    setLoading(true);
    AllocationServiceabilityApi.api
      .merchantAllocationList(selectedMerchant)
      .then((e) => {
        if (e.data && Array.isArray(e.data.data)) {
          setMerchantCodList(e.data.data);
          initializeRowStates(e.data.data);
          setLoading(false);
        } else {
          setShowSnackbar(true);
          setMerchantCodList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowSnackbar(true);
        console.error("API call error: ", error);
      });
  };
  useEffect(() => {
    handleTableData();
  }, [selectedMerchant]);

  const initializeRowStates = (data: any[]) => {
    const initialStates = data.reduce((acc: any, row: any) => {
      acc[row.id] = {
        clientType: row.clientType,
        allocationType: row.allocationType,
        originalClientType: row.clientType,
        originalAllocationType: row.allocationType,
        city: row.city,
        merchant: row.merchant,
      };
      return acc;
    }, {});
    setRowStates(initialStates);
  };

  const handleChange = (id: number, field: string, value: any) => {
    setRowStates((prevStates: any) => {
      const originalValue =
        prevStates[id][
          `original${field.charAt(0).toUpperCase() + field.slice(1)}`
        ];
      const newValue = value === originalValue ? originalValue : value;
      return {
        ...prevStates,
        [id]: {
          ...prevStates[id],
          [field]: Number(newValue),
        },
      };
    });
  };

  const hasChanges = (id: number) => {
    const row = rowStates[id];
    if (!row) {
      return false;
    }
    return (
      row.clientType !== row.originalClientType ||
      row.allocationType !== row.originalAllocationType
    );
  };

  const handleSave = (id: number) => {
    const row = rowStates[id];
    AllocationServiceabilityApi.api
      .updateMerchantAllocationType(id, row.clientType, row.allocationType)
      .then(() => {
        setSnackbarMessage("Update successful!");
        setShowSuccessSnackbar(true);
        setOpenLimitDialog(false);
        setRowStates((prevStates: any) => ({
          ...prevStates,
          [id]: {
            ...prevStates[id],
            originalClientType: row.clientType,
            originalAllocationType: row.allocationType,
          },
        }));
      })
      .catch((error) => {
        console.error("API call error: ", error);
        setSnackbarMessage("Failed to update.");
        setShowErrorSnackbar(true);
      });
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSuccessSnackbar(false);
    handleTableData();
  };
  const handleErrorSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
  };
  const handleCancelSave = () => {
    setOpenLimitDialog(false);
  };

  return (
    <>
      {loading ? (
        <LinearProgress
          sx={{
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#00CD5B",
            },
          }}
        />
      ) : (
        <>
          <TableContainer className="">
            <div className="flex items-center justify-between py-4 px-3 border-slate-200 border-solid">
              <div className="border-2 rounded-2xl px-2 ml-2">
                <label className="text-[#727597] items-center text-sm font-normal font-inter">
                  Merchant :
                </label>
                <select
                  name="merchant"
                  id="merchant"
                  className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[150px]"
                  value={selectedMerchant}
                  onChange={(e) => {
                    setSelectedMerchant(e.target.value);
                  }}
                >
                  {merchantData?.map((merchant: any) => (
                    <option
                      key={merchant.merchantId}
                      value={merchant.merchantId}
                    >
                      {merchant.merchantName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex">
                <div className="border-2 rounded-2xl px-2">
                  <InputBase
                    placeholder="Search by City"
                    className="flex-grow"
                    inputProps={{ "aria-label": "search" }}
                    value={searchVal}
                    onChange={(search) => setSearchVal(search.target.value)}
                  />
                  <SearchIcon
                    style={{ color: "#727597" }}
                    className="cursor-pointer ml-4"
                  />
                </div>
              </div>
            </div>
            <hr />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Merchant
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    City
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Client Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Allocation Type
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#ACACAC",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMerchantCodList?.map((row: any, index: any) => (
                  <React.Fragment key={index}>
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Chip
                          label={`${row?.merchant}`}
                          size="small"
                          style={{
                            backgroundColor: "#2D0F8A1F",
                            color: "#2D0F8A",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {cityCodeMap[row?.city]}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <div className="border-[1px] border-[#ECEEFF] w-4/5 rounded-2xl px-2 ml-2">
                          <select
                            name="clientType"
                            id="clientType"
                            className="text-[#727597] text-sm font-normal font-inter px-1 py-0.5 min-w-[140px]"
                            value={rowStates[row.id]?.clientType}
                            onChange={(e) =>
                              handleChange(row.id, "clientType", e.target.value)
                            }
                          >
                            <option value={1}>Primary Client</option>
                            <option value={0}>Both (P/S)</option>
                          </select>
                        </div>
                      </TableCell>

                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <div className="border-[1px] border-[#ECEEFF] w-5/6 rounded-2xl px-2 ml-2">
                          <select
                            name="allocationType"
                            id="allocationType"
                            className="text-[#727597] w-full text-sm font-normal font-inter px-1 py-0.5 min-w-[150px]"
                            value={rowStates[row.id]?.allocationType}
                            onChange={(e) =>
                              handleChange(
                                row.id,
                                "allocationType",
                                e.target.value
                              )
                            }
                          >
                            <option value={0}>Force Allocate</option>
                            <option value={1}>
                              Broadcast to Dedicated Riders
                            </option>
                            <option value={2}>
                              Broadcast to Hybrid Riders
                            </option>
                          </select>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#414141",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Button
                          className="mb-2"
                          variant="contained"
                          onClick={() => {
                            setOpenLimitDialog(true);
                            setCurrentRow(row.id);
                          }}
                          sx={{
                            textTransform: "none",
                            px: 3,
                            py: 1,
                            bgcolor: "#171F21",
                            "&:hover": { bgcolor: "#171F2180" },
                            borderRadius: "50px",
                          }}
                          disabled={!hasChanges(row.id)}
                        >
                          Save
                        </Button>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
            <Dialog open={openLimitDialog} onClose={handleCancelSave}>
              <DialogTitle>Update Allocation</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Update for {rowStates[currentRow]?.merchant}{" "}
                  {rowStates[currentRow]?.city}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ textTransform: "none", color: "#171F21", py: 1, px: 5 }}
                  onClick={handleCancelSave}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    ml: 3,
                    color: "white",
                    bgcolor: "#171F21",
                    borderRadius: "50px",
                    textTransform: "none",
                    py: 1,
                    px: 5,
                    "&:hover": { bgcolor: "#171F2180" },
                  }}
                  onClick={() => {
                    handleSave(currentRow);
                  }}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </TableContainer>
          <Snackbar
            open={showSuccessSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {SnackbarMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={showErrorSnackbar}
            autoHideDuration={3000}
            onClose={handleErrorSnackbarClose}
          >
            <Alert
              onClose={handleErrorSnackbarClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {SnackbarMessage}
            </Alert>
          </Snackbar>

          <Snackbar
            open={showSnackbar}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              sx={{ width: "100%" }}
              onClose={handleClose}
              severity="error"
            >
              No data found
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
}
